import { useCoinValue } from "@organisms/coin/provider";
import { Box, Text } from "grommet";

const Badge = ({ slug, ...rest }) => {
  const presaleType = useCoinValue(slug, "presaleType");

  return (
    <Box align="center" {...rest}>
      <Box
        round="medium"
        pad={{ vertical: "6px", horizontal: "xsmall" }}
        background="#2F3441"
      >
        <Text size="xsmall">
          {!presaleType || presaleType === "Normal Presale"
            ? "Presale"
            : presaleType}
        </Text>
      </Box>
    </Box>
  );
};

export default Badge;
